define('axiom-retail-energy/components/file-upload', ['exports', 'ember', 'axiom-retail-energy/config/environment'], function (exports, inject, config) {

  'use strict';

  var FileUploadComponent;

  FileUploadComponent = inject['default'].Component.extend({
    notify: inject['default'].inject.service('notify'),
    actions: {
      fileReceived: function fileReceived(url) {
        return this.set('uploadedFile', url);
      },
      sendFile: function sendFile() {
        var comments, company, email, fileUrl, name, notify, phone;
        name = this.get('name');
        company = this.get('company');
        phone = this.get('phone');
        email = this.get('email');
        comments = this.get('comments');
        fileUrl = this.get('uploadedFile');
        notify = this.get('notify');
        return $.ajax({
          type: 'POST',
          url: 'https://mandrillapp.com/api/1.0/messages/send.json',
          data: {
            key: config['default'].mandrillKey,
            message: {
              from_email: email,
              from_name: name,
              headers: {
                'Reply-To': email
              },
              subject: 'Website Contact Form Submission',
              text: name + ' at ' + company + ' wants a free analysis and can be reached at ' + email + ' or ' + phone + '. Their uploaded file is available at: ' + fileUrl + '. They also had this to say: ' + comments,
              to: [{
                email: 'info@axiomretailenergy.com',
                type: 'to'
              }]
            }
          },
          beforeSend: (function (_this) {
            return function () {};
          })(this),
          error: (function (_this) {
            return function (error, error2) {
              return notify.alert('Message failed to send. Please try again.');
            };
          })(this),
          success: (function (_this) {
            return function (data) {
              notify.success('Your message has been sent. Thank you!');
              return $('.modal').modal('hide');
            };
          })(this),
          complete: (function (_this) {
            return function () {
              _this.set('name', null);
              _this.set('company', null);
              _this.set('phone', null);
              _this.set('email', null);
              _this.set('comments', null);
              return _this.set('fileUrl', null);
            };
          })(this)
        });
      }
    }
  });

  exports['default'] = FileUploadComponent;

});