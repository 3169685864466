define('axiom-retail-energy/components/service-toggle', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ServiceToggleComponent;

  ServiceToggleComponent = Ember['default'].Component.extend({
    linkText: null,
    service: null,
    step: null,
    actions: {
      openService: function openService(service, step) {
        var _el, target;
        _el = this.get('element');
        target = $(_el).find('h5');
        $(service).siblings('.service').removeClass('open-service');
        $(_el).siblings('div').find('h5').removeClass('open-service');
        $(_el).siblings('div').find('button').removeClass('tcon-transform');
        $('.step').find('h5').not(target).removeClass('open-service');
        if ($(target).hasClass('open-service')) {
          $(target).removeClass('open-service');
          $(target).find('button').removeClass('tcon-transform');
          $(service).removeClass('open-service');
          return $(step).removeClass('open-service');
        } else {
          $(target).addClass('open-service');
          $(target).find('button').addClass('tcon-transform');
          $(service).addClass('open-service');
          return $(step).addClass('open-service');
        }
      }
    }
  });

  exports['default'] = ServiceToggleComponent;

});