define('axiom-retail-energy/components/state-locate', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var StateLocateComponent;

  StateLocateComponent = Ember['default'].Component.extend({
    didInsertElement: function didInsertElement() {
      var energyStates, gasStates, limitedStates, output, stateImage, stateName;
      output = document.getElementById("output");
      stateImage = $('.state-image img');
      stateName = $('#state-name');
      gasStates = ['Washington', 'Alaska', 'Hawaii', 'Idaho', 'Nebraska', 'Kansas', 'Oklahoma', 'Minnesota', 'Iowa', 'Missouri', 'Arkansas', 'Louisiana', 'Wisconsin', 'Indiana', 'Tennessee', 'Mississippi', 'Alabama', 'Florida', 'North Carolina', 'South Carolina', 'Texas', 'Illinois', 'Ohio', 'Delaware', 'Maryland', 'Rhode Island', 'Pennsylvania', 'New Jersey', 'New York', 'New Hampshire', 'Maine', 'Connecticut', 'Massachusetts', 'Oregon', 'California'];
      energyStates = ['Michigan', 'Montana', 'Texas', 'Illinois', 'Ohio', 'Delaware', 'Maryland', 'Rhode Island', 'Pennsylvania', 'New Jersey', 'New York', 'New Hampshire', 'Maine', 'Connecticut', 'Massachusetts', 'Oregon', 'California'];
      limitedStates = ['Wyoming', 'Nevada', 'Utah', 'Arizona', 'Colorado', 'New Mexico', 'North Dakota', 'South Dakota', 'Vermont', 'West Virginia', 'Virginia', 'Georgia', 'Kentucky'];
      return $('#state-select').change(function () {
        var imageSource, state;
        state = $(this).val();
        if (state.length < 10) {
          $('.cs-select').css('width', state.length * 10.7);
        } else {
          $('.cs-select').css('width', state.length * 9.4);
        }
        imageSource = "assets/images/home/states/" + state.toLowerCase().dasherize() + ".svg";
        $(stateImage).attr('src', imageSource);
        stateName.text(state.toUpperCase());
        $('.gas, .energy, .limited').removeClass('animated fadeInRight');
        if (gasStates.indexOf(state) > -1) {
          $('.gas').addClass('animated fadeInRight');
          $('.state-info p').text(state + ' allows natural gas consumers to choose their supplier. Please contact us to learn more.');
        }
        if (energyStates.indexOf(state) > -1) {
          $('.energy').addClass('animated fadeInRight');
          $('.state-info p').text(state + ' allows electricity consumers to choose their supplier. Please contact us to learn more.');
        }
        if (limitedStates.indexOf(state) > -1) {
          $('.limited').addClass('animated fadeInRight');
          $('.state-info p').text(state + ' offers limited opportunities for consumer choice. Please contact us to learn more.');
        }
        if (gasStates.indexOf(state) > -1 && energyStates.indexOf(state) > -1) {
          return $('.state-info p').text(state + ' allows consumers to choose suppliers for electricity and natural gas. Please contact us to learn more.');
        }
      });
    }
  });

  exports['default'] = StateLocateComponent;

});