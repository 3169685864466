define('axiom-retail-energy/routes/about', ['exports', 'ember', 'jquery'], function (exports, Ember, $) {

  'use strict';

  var AboutRoute;

  AboutRoute = Ember['default'].Route.extend({
    activate: function activate() {
      this._super();
      return particlesJS.load('particles-js', 'assets/particles.json');
    },
    deactivate: function deactivate() {
      this._super();
      return $['default']('#particles-js').remove();
    }
  });

  exports['default'] = AboutRoute;

});