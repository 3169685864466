define('axiom-retail-energy/routes/application', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ApplicationRoute;

  ApplicationRoute = Ember['default'].Route.extend({
    actions: {
      didTransition: function didTransition() {
        window.scrollTo(0, 0);
        $('nav.top-bar-container').removeClass('menu-open');
        return $('nav.top-bar-container li').removeClass('animated fadeInDown');
      },
      openMenu: function openMenu() {
        if ($('nav.top-bar-container').hasClass('menu-open')) {
          $('nav.top-bar-container').removeClass('menu-open');
          return $('nav.top-bar-container li').removeClass('animated fadeInDown');
        } else {
          $('nav.top-bar-container').addClass('menu-open');
          return $('nav.top-bar-container li').addClass('animated fadeInDown');
        }
      }
    }
  });

  exports['default'] = ApplicationRoute;

});