define('axiom-retail-energy/router', ['exports', 'ember', 'axiom-retail-energy/config/environment'], function (exports, Ember, config) {

  'use strict';

  var Router;

  Ember['default'].Route.reopen({
    activate: function activate() {
      var cssClass;
      cssClass = this.toCssClass();
      if (cssClass !== 'application') {
        return Ember['default'].$('body').addClass(cssClass);
      }
    },
    deactivate: function deactivate() {
      return Ember['default'].$('body').removeClass(this.toCssClass());
    },
    toCssClass: function toCssClass() {
      return this.routeName.replace(/\./g, '-').dasherize();
    }
  });

  Router = Ember['default'].Router.extend({
    location: config['default'].locationType
  });

  Router.map(function () {
    this.route('home', {
      path: ''
    });
    this.route('about');
    this.route('case-studies');
    return this.route('contact');
  });

  exports['default'] = Router;

});