define('axiom-retail-energy/templates/partials/svgs/logo', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 42,
            "column": 0
          }
        },
        "moduleName": "axiom-retail-energy/templates/partials/svgs/logo.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        dom.setNamespace("http://www.w3.org/2000/svg");
        var el1 = dom.createElement("svg");
        dom.setAttribute(el1,"version","1.1");
        dom.setAttribute(el1,"id","Layer_1");
        dom.setAttribute(el1,"xmlns","http://www.w3.org/2000/svg");
        dom.setAttribute(el1,"xmlns:xlink","http://www.w3.org/1999/xlink");
        dom.setAttribute(el1,"x","0px");
        dom.setAttribute(el1,"y","0px");
        dom.setAttribute(el1,"viewBox","0 0 296 24");
        dom.setAttribute(el1,"style","enable-background:new 0 0 296 24;");
        dom.setAttributeNS(el1,"http://www.w3.org/XML/1998/namespace","xml:space","preserve");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("style");
        dom.setAttribute(el2,"type","text/css");
        var el3 = dom.createTextNode("\n	.st0{fill:#42464B;}\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("g");
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M14.4,15.5H5.4l-2.7,6.7H1.4l8.5-21l8.5,21h-1.3L14.4,15.5z M13.9,14.3l-4-9.8l-4,9.8H13.9z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M25.7,11.8l-5.3-9.9h1.4l4.7,8.7l4.7-8.7h1.4l-5.3,9.9l5.6,10.4h-1.4l-5-9.2l-4.9,9.2h-1.4L25.7,11.8z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M38,1.8v20.3h-1.3V1.8H38z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M53,1.6c1.9,0,3.6,0.5,5.2,1.4c1.6,0.9,2.9,2.2,3.9,3.8c1,1.6,1.4,3.3,1.4,5.2c0,1.9-0.5,3.6-1.4,5.2\n		s-2.2,2.9-3.9,3.8c-1.6,0.9-3.4,1.4-5.2,1.4S49.4,22,47.8,21c-1.6-0.9-2.9-2.2-3.9-3.8s-1.4-3.3-1.4-5.2c0-1.4,0.3-2.7,0.8-4\n		c0.5-1.3,1.3-2.4,2.3-3.4c1-1,2.1-1.7,3.4-2.3C50.3,1.8,51.6,1.6,53,1.6z M53,2.8c-1.7,0-3.2,0.4-4.6,1.2C47,4.9,45.9,6,45,7.4\n		c-0.8,1.4-1.3,2.9-1.3,4.6c0,1.6,0.4,3.2,1.3,4.6c0.8,1.4,2,2.5,3.4,3.4c1.4,0.8,3,1.2,4.6,1.2c1.6,0,3.2-0.4,4.6-1.3\n		c1.4-0.8,2.6-2,3.4-3.4c0.8-1.4,1.3-2.9,1.3-4.6c0-1.2-0.2-2.4-0.7-3.5c-0.5-1.1-1.2-2.1-2-3c-0.8-0.8-1.8-1.5-3-2\n		C55.5,3,54.3,2.8,53,2.8z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M88.6,22.2L85.1,5.3H85L78,22.8L70.9,5.3h-0.1l-3.5,16.8h-1.2l4.4-21L78,19.7l7.5-18.5l4.4,21H88.6z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M101.4,2.7c2.5,0,4,0.8,5,1.7c0.8,0.8,1.7,2.2,1.7,4.3c0,1.2-0.3,2.7-1.5,4c-0.7,0.6-1.6,1.2-2.7,1.5l6,8h-5.5\n		l-5.3-7.7v7.7h-4.5V2.7H101.4z M99.1,11.5h1c0.8,0,1.9-0.1,2.7-0.8c0.3-0.3,0.7-0.9,0.7-1.9c0-1.1-0.5-1.7-0.9-2\n		c-0.7-0.6-1.9-0.6-2.5-0.6h-1.1V11.5z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M124.8,6.5h-6.5v3.8h6.2V14h-6.2v4.3h6.5v3.8h-11V2.7h11V6.5z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M136.7,6.5v15.7h-4.5V6.5H128V2.7h13v3.8H136.7z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M155,18.5h-7.4l-1.5,3.7h-4.7l7.9-19.5h4.1l7.7,19.5h-4.7L155,18.5z M153.8,15l-2.4-6.5L149,15H153.8z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M169.4,2.7v19.5h-4.5V2.7H169.4z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M179.7,2.7v15.7h6v3.8h-10.5V2.7H179.7z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M199.4,3.1H191v7.4h8.2v1.2H191v9.3h8.4v1.2h-9.7V1.8h9.7V3.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M223.1,1.8v21L206.1,4.7v17.5h-1.3V1.6l17.1,18V1.8H223.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M238.5,3.1h-8.4v7.4h8.2v1.2h-8.2v9.3h8.4v1.2h-9.7V1.8h9.7V3.1z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M247.2,12.5l6.7,9.6h-1.5l-6.6-9.6h-0.7v9.6h-1.3V1.8h2.5c1.1,0,2,0.1,2.7,0.2c0.7,0.1,1.4,0.4,2,0.8\n		c1.4,1,2.1,2.4,2.1,4.4c0,1.1-0.2,2.1-0.7,2.9c-0.5,0.8-1.2,1.4-2.1,1.8C249.4,12.3,248.4,12.5,247.2,12.5z M245.1,11.3h1.6\n		c0.8,0,1.5-0.1,2-0.2c0.6-0.1,1.1-0.4,1.5-0.8c1-0.8,1.4-1.8,1.4-3.2c0-0.5-0.1-1-0.2-1.5c-0.2-0.5-0.4-0.9-0.7-1.3\n		c-0.3-0.4-0.7-0.7-1.2-0.9c-0.7-0.3-1.9-0.5-3.6-0.5h-0.8V11.3z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M268.8,12.4h8.6c0,0.9-0.1,1.8-0.2,2.5c-0.1,0.8-0.3,1.5-0.6,2.2c-0.3,0.7-0.8,1.4-1.3,2c-1,1-2.1,1.8-3.5,2.4\n		c-1.4,0.6-2.8,0.9-4.2,0.9c-1.4,0-2.7-0.3-4-0.8c-1.3-0.5-2.4-1.3-3.4-2.3c-1-1-1.7-2.1-2.3-3.3c-0.5-1.3-0.8-2.6-0.8-3.9\n		c0-1.9,0.5-3.7,1.4-5.3s2.2-2.9,3.9-3.8c1.6-0.9,3.4-1.4,5.3-1.4c1.2,0,2.3,0.2,3.4,0.6c1.1,0.4,2.1,0.9,3,1.6\n		c0.9,0.7,1.7,1.6,2.4,2.5l-1.1,0.8c-0.4-0.7-0.9-1.3-1.5-1.8c-0.6-0.5-1.2-1-1.8-1.4c-0.7-0.4-1.4-0.7-2.1-0.9\n		c-0.8-0.2-1.5-0.3-2.4-0.3c-1.6,0-3.1,0.4-4.6,1.3c-1.4,0.9-2.6,2-3.4,3.4c-0.8,1.4-1.3,2.9-1.3,4.5c0,1.6,0.4,3.2,1.2,4.6\n		c0.8,1.4,2,2.5,3.4,3.4c1.4,0.8,3,1.3,4.6,1.3c2.1,0,4.1-0.7,6-2.2c0.6-0.5,1-1,1.4-1.5c0.4-0.6,0.6-1.1,0.8-1.8\n		c0.2-0.6,0.3-1.3,0.3-2.1h-7.3V12.4z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n	");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3,"class","st0");
        dom.setAttribute(el3,"d","M286.6,13.5l-6.8-11.6h1.4l6,10.3l6-10.3h1.4l-6.8,11.6v8.7h-1.3V13.5z");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() { return []; },
      statements: [

      ],
      locals: [],
      templates: []
    };
  }()));

});