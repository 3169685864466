define('axiom-retail-energy/components/contact-form', ['exports', 'ember', 'axiom-retail-energy/config/environment'], function (exports, inject, config) {

  'use strict';

  var ContactFormComponent;

  ContactFormComponent = inject['default'].Component.extend({
    notify: inject['default'].inject.service('notify'),
    actions: {
      sendMessage: function sendMessage() {
        var comments, company, email, name, notify, phone;
        name = this.get('name');
        company = this.get('company');
        phone = this.get('phone');
        email = this.get('email');
        comments = this.get('comments');
        notify = this.get('notify');
        if (!(comments === '' || comments === void 0)) {
          return $.ajax({
            type: 'POST',
            url: 'https://mandrillapp.com/api/1.0/messages/send.json',
            data: {
              key: config['default'].mandrillKey,
              message: {
                from_email: 'donotreply@axiomretailenergy.com',
                from_name: name,
                headers: {
                  'Reply-To': email
                },
                subject: 'Website Contact Form Submission',
                text: name + ' is contacting you on behalf of ' + company + ' and can be reached at ' + email + ' or ' + phone + '. They have this to say: ' + comments,
                to: [{
                  email: 'info@axiomretailenergy.com',
                  type: 'to'
                }]
              }
            },
            beforeSend: (function (_this) {
              return function () {};
            })(this),
            error: (function (_this) {
              return function (error, error2) {
                return notify.alert('Message failed to send. Please try again.');
              };
            })(this),
            success: (function (_this) {
              return function (data) {
                return notify.success('Your message has been sent. Thank you!');
              };
            })(this),
            complete: (function (_this) {
              return function () {
                _this.set('name', null);
                _this.set('company', null);
                _this.set('phone', null);
                _this.set('email', null);
                return _this.set('comments', null);
              };
            })(this)
          });
        }
      }
    }
  });

  exports['default'] = ContactFormComponent;

});