define('axiom-retail-energy/templates/components/file-upload', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    return {
      meta: {
        "revision": "Ember@1.13.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "axiom-retail-energy/templates/components/file-upload.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("Get a Free Analysis");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createTextNode("Axiom is committed to providing the lowest possible energy prices to its clients and customers. Send us your energy bill and we'll provide for you a free analysis.");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-group");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","input-group");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("input");
        dom.setAttribute(el3,"type","submit");
        dom.setAttribute(el3,"value","SEND");
        dom.setAttribute(el3,"class","hollow-button");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [7]);
        var morphs = new Array(7);
        morphs[0] = dom.createElementMorph(element0);
        morphs[1] = dom.createMorphAt(element0,5,5);
        morphs[2] = dom.createMorphAt(element1,1,1);
        morphs[3] = dom.createMorphAt(element1,3,3);
        morphs[4] = dom.createMorphAt(element1,5,5);
        morphs[5] = dom.createMorphAt(element1,7,7);
        morphs[6] = dom.createMorphAt(dom.childAt(element0, [9]),1,1);
        return morphs;
      },
      statements: [
        ["element","action",["sendFile"],["on","submit"],["loc",[null,[1,6],[1,39]]]],
        ["inline","drop-zone",[],["includeLabel",false,"actionName","fileReceived"],["loc",[null,[4,2],[4,60]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","name",["loc",[null,[6,18],[6,22]]]]],[],[]],"placeholder","Name"],["loc",[null,[6,4],[6,43]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","company",["loc",[null,[7,18],[7,25]]]]],[],[]],"placeholder","Company"],["loc",[null,[7,4],[7,49]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","phone",["loc",[null,[8,18],[8,23]]]]],[],[]],"placeholder","Phone","type","tel"],["loc",[null,[8,4],[8,56]]]],
        ["inline","input",[],["value",["subexpr","@mut",[["get","email",["loc",[null,[9,18],[9,23]]]]],[],[]],"placeholder","Email","type","email"],["loc",[null,[9,4],[9,58]]]],
        ["inline","textarea",[],["value",["subexpr","@mut",[["get","comments",["loc",[null,[12,21],[12,29]]]]],[],[]],"placeholder","Comments"],["loc",[null,[12,4],[12,54]]]]
      ],
      locals: [],
      templates: []
    };
  }()));

});