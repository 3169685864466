define('axiom-retail-energy/components/scroll-magic', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var ScrollMagicComponent;

  ScrollMagicComponent = Ember['default'].Component.extend({
    triggerElement: null,
    targetElement: null,
    toggleClass: null,
    didInsertElement: function didInsertElement() {
      var controller, targetElement, toggleClass, triggerElement;
      triggerElement = this.get('triggerElement');
      targetElement = this.get('targetElement');
      toggleClass = this.get('toggleClass');
      controller = new ScrollMagic.Controller();
      return new ScrollMagic.Scene({
        triggerElement: triggerElement
      }).setClassToggle(targetElement, toggleClass).reverse(false).addTo(controller);
    }
  });

  exports['default'] = ScrollMagicComponent;

});