define('axiom-retail-energy/components/drop-zone', ['exports', 'ember', 'axiom-retail-energy/config/environment'], function (exports, Ember, config) {

  'use strict';

  var DropZoneComponent,
      extend = function extend(child, parent) {
    for (var key in parent) {
      if (hasProp.call(parent, key)) child[key] = parent[key];
    }function ctor() {
      this.constructor = child;
    }ctor.prototype = parent.prototype;child.prototype = new ctor();child.__super__ = parent.prototype;return child;
  },
      hasProp = ({}).hasOwnProperty;

  DropZoneComponent = (function (superClass) {
    extend(DropZoneComponent, superClass);

    function DropZoneComponent() {
      return DropZoneComponent.__super__.constructor.apply(this, arguments);
    }

    DropZoneComponent.prototype.includeLabel = true;

    DropZoneComponent.prototype.remoteUrl = '';

    DropZoneComponent.prototype.classNames = ['dropzone-container'];

    DropZoneComponent.prototype.didInsertElement = function () {
      return Ember['default'].run.scheduleOnce('afterRender', this, function () {
        var _this, url;
        this.addObserver('remoteUrl', function () {
          var action;
          action = this.get('actionName');
          if (action === void 0) {
            action = 'remoteUrlReceived';
          }
          return this.get('targetObject').send(action, this.get('remoteUrl'));
        });
        this.addObserver('currentFile', function () {
          var file;
          file = this.get('currentFile');
          if (file === void 0 || file === null || file === '') {
            return this.sendAction('fileRemoved');
          }
        });
        Dropzone.autoDiscover = false;
        _this = this;
        url = [config['default'].remoteHost, 'presigned_post'].join('/');
        return $.getJSON(url, (function (_this) {
          return function (data) {
            var $el, $target, _dropZone, currentFile, dzId, dzIdHash, file, temp;
            _this.set('url', data.url);
            _this.set('policy', JSON.parse(data.policy));
            dzId = _this.elementId + '-dropzone';
            dzIdHash = '#' + dzId;
            currentFile = _this.get('currentImage');
            $el = $(_this.get('element'));
            $target = $el.find('.dropzone');
            $target.attr('id', dzId);
            _dropZone = new Dropzone(dzIdHash, {
              url: _this.get('url'),
              maxFiles: 1,
              addRemoveLinks: true
            });
            if (currentFile != null) {
              file = currentFile.substring(currentFile.lastIndexOf('/') + 1);
              temp = {
                name: file,
                size: 12345
              };
              _dropZone.options.addedfile.call(_dropZone, temp);
              _dropZone.options.thumbnail.call(_dropZone, temp, currentFile);
            }
            _dropZone.on('sending', function (file, xhr, formData) {
              var policy, prop, results;
              policy = _this.get('policy');
              results = [];
              for (prop in policy) {
                results.push(formData.append(prop, policy[prop]));
              }
              return results;
            });
            _dropZone.on('success', function (file, response) {
              var location, xml;
              xml = $($.parseXML(response));
              location = xml.find('Location');
              if (location != null) {
                return _this.set('remoteUrl', location.text());
              }
            });
            return _dropZone.on('removedfile', function () {
              return _this.set('currentFile', '');
            });
          };
        })(this));
      });
    };

    return DropZoneComponent;
  })(Ember['default'].Component);

  exports['default'] = DropZoneComponent;

});