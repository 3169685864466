define('axiom-retail-energy/routes/home', ['exports', 'ember', 'jquery', 'axiom-retail-energy/effects/particle-mesh'], function (exports, Ember, $, ParticleMesh) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    headerAnimation: null,

    activate: function activate() {
      var _this = this;

      this._super();

      Ember['default'].run.scheduleOnce('afterRender', this, function () {
        Ember['default'].$('.owl-carousel').owlCarousel({
          loop: true,
          items: 1
        });
      });

      var animation = this.get('headerAnimation');
      if (Ember['default'].isNone(animation)) {
        Ember['default'].run.scheduleOnce('afterRender', this, function () {
          animation = ParticleMesh['default'].create({
            elementId: 'header-canvas',
            container: '#home-header'
          });
          animation.activate();
          _this.set('headerAnimation', animation);

          Ember['default'].$(window).resize(function () {
            animation._windowDidResize();
          });
        });
      } else {
        animation.activate();

        Ember['default'].$(window).resize(function () {
          animation._windowDidResize();
        });
      }
    },

    deactivate: function deactivate() {
      this._super();
      var animation = this.get('headerAnimation');
      animation.deactivate();
      this.set('headerAnimation', null);
    },

    actions: {
      showModal: function showModal() {
        $['default']('.modal').modal('show');
      },
      closeService: function closeService() {
        $['default']('.open-service').removeClass('open-service');
        $['default']('.tcon-transform').removeClass('tcon-transform');
      }
    }
  });

});