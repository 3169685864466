define('axiom-retail-energy/effects/particle-mesh', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  var SEPARATION = 100,
      AMOUNTX = 100,
      AMOUNTY = 70;

  var PI2 = Math.PI * 2;

  exports['default'] = Ember['default'].Object.extend({
    _count: 0,
    _scene: null,
    _camera: null,
    _renderer: null,
    _particles: null,

    // Initialize animation
    _initialize: function _initialize() {
      var element = $('<div>');
      var elementId = this.get('elementId');
      var container = Ember['default'].$(this.get('container'));

      // console.log(container);

      // Set the ID for the canvas element
      element.attr('id', elementId);

      // Create the camera
      var camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
      camera.position.z = 10000;

      var scene = new THREE.Scene();
      var particles = [];

      var material = new THREE.SpriteCanvasMaterial({
        color: 0xffffdf,
        program: function program(context) {

          context.beginPath();
          context.arc(0, 0, 0.5, 0, PI2, true);
          context.fill();
        }
      });

      var i = 0;
      var particle = null;
      for (var ix = 0; ix < AMOUNTX; ix++) {
        for (var iy = 0; iy < AMOUNTY; iy++) {
          particle = particles[i++] = new THREE.Sprite(material);
          particle.position.x = ix * SEPARATION - AMOUNTX * SEPARATION / 2;
          particle.position.z = iy * SEPARATION - AMOUNTY * SEPARATION / 2;
          scene.add(particle);
        }
      }
      //     }

      var renderer = new THREE.CanvasRenderer({ alpha: true });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      element.append(renderer.domElement);

      // console.log(element.children());
      container.append(element);

      this.set('_scene', scene);
      this.set('_camera', camera);
      this.set('_renderer', renderer);
      this.set('_particles', particles);

      // setTimeout(() => {
      //
      //   container.append(element);
      // }, 1000);

      // Start animation
      this._animate();
    },

    // Dispose animation
    _dispose: function _dispose() {
      var scene = this.get('_scene');
      var renderer = this.get('_renderer');
      var particles = this.get('_particles');

      scene.children.forEach(function (child) {
        scene.remove(child);
        try {
          renderer.deallocateObject(child);
        } catch (e) {}
      });

      this.set('_particles', null);
      cancelAnimationFrame(this.get('animationId'));
      Ember['default'].$('#' + this.get('elementId')).remove();
    },

    _animate: function _animate() {
      // requestAnimationFrame(this.get('_animate')));
      var animId = requestAnimationFrame(this._animate.bind(this));
      this.set('animationId', animId);
      this._render();
    },

    _render: function _render() {
      var scene = this.get('_scene');
      var camera = this.get('_camera');
      var particles = this.get('_particles');
      var count = this.get('_count');
      var renderer = this.get('_renderer');

      // console.log(count);

      // console.log(particles);
      camera.position.set(0, 355, 122);

      var i = 0;
      for (var x = 0; x < AMOUNTX; x++) {
        for (var y = 0; y < AMOUNTY; y++) {
          var particle = particles[i++];
          particle.position.y = Math.sin((x + count) * 0.3) * 50 + Math.sin((y + count) * 0.5) * 50;
          particle.scale.x = particle.scale.y = (Math.sin((x + count) * 0.3) + 1) * 4 + (Math.sin((y + count) * 0.5) + 1) * 4;
        }
      }

      // console.log(renderer);
      renderer.render(scene, camera);
      count += 0.05;
      this.set('_count', count);
    },

    _windowDidResize: function _windowDidResize() {
      this._dispose();
      this._initialize();
    },

    activate: function activate() {
      this._initialize();
    },

    deactivate: function deactivate() {
      this._dispose();
    }
  });

});