/* jshint ignore:start */

define('axiom-retail-energy/config/environment', ['ember'], function(Ember) {
  var prefix = 'axiom-retail-energy';
/* jshint ignore:start */

try {
  var metaName = prefix + '/config/environment';
  var rawConfig = Ember['default'].$('meta[name="' + metaName + '"]').attr('content');
  var config = JSON.parse(unescape(rawConfig));

  return { 'default': config };
}
catch(err) {
  throw new Error('Could not read config from meta tag with name "' + metaName + '".');
}

/* jshint ignore:end */

});

if (runningTests) {
  require("axiom-retail-energy/tests/test-helper");
} else {
  require("axiom-retail-energy/app")["default"].create({"name":"axiom-retail-energy","version":"0.0.0+b72b26fe"});
}

/* jshint ignore:end */
