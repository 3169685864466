define('axiom-retail-energy/routes/case-studies', ['exports', 'ember', 'jquery'], function (exports, Ember, $) {

  'use strict';

  var CaseStudiesRoute;

  CaseStudiesRoute = Ember['default'].Route.extend({
    actions: {
      expandStudy: function expandStudy(study) {
        var target;
        target = $['default'](study);
        if (target.hasClass('study')) {
          target.siblings().removeClass('expanded');
          return target.addClass('expanded');
        }
      }
    }
  });

  exports['default'] = CaseStudiesRoute;

});